import "entry/common"

// Workaround a font issue on Chrome/Windows.
import "lib/chrome_fonts"

// these are dependencies of draggable/droppable
// probably not in the correct place, but it works
// well enough
import "jquery-ui/ui/widget"
import "jquery-ui/ui/widgets/mouse"

// Adds touch support to jquery ui must be required after
// jquery-ui mouse
//
// We also need to inject a jQuery import, because touch-punch
// is unaware of javascript modules
//
// Obviously we want to aim to get rid of nonsense like this :)
import "imports-loader?imports=default|jquery|jQuery!jquery-ui-touch-punch/jquery.ui.touch-punch"

// these are being called from inside ERB views, so it needs global scope for now
window.TC.BasePage = require("pages/base_page").default
window.TC.ArticleFinishedPage = require("pages/article_finished_page").default
window.TC.ChooserView = require("views/chooser_view").default
window.TC.ColumnPage = require("pages/column_page").default
window.TC.EditProfilePage = require("pages/edit_profile_page").default
window.TC.InfoPage = require("pages/info_page").default
window.TC.InstitutionPage = require("pages/institution_page").default
window.TC.NewEventPage = require("pages/new_event_page").default
window.TC.NewRegistrationPage = require("pages/new_registration_page").default
window.TC.NewSessionPage = require("pages/new_session_page").default
window.TC.PartnerPage = require("pages/partner_page").default
window.TC.PodcastsPage = require("pages/podcasts_page").default
window.TC.PodcastShowPage = require("pages/podcast_show_page").default
window.TC.ProfileCredentialsPage = require("pages/profile_credentials_page").default
window.TC.ProfileNewslettersPage = require("pages/profile_newsletters_page").default
window.TC.ProfilePage = require("pages/profile_page").default
window.TC.SearchPage = require("pages/search_page").default
