import $ from "jquery"
import { each } from "lodash"
import ControlView from "views/control_view"
/* eslint-disable
    consistent-return,
    constructor-super,
    func-names,
    no-constant-condition,
    no-eval,
    no-multi-assign,
    no-param-reassign,
    no-return-assign,
    no-shadow,
    no-this-before-super,
    no-undef,
    no-underscore-dangle,
    no-unused-expressions,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let SuperLightboxView

// Represents a lightbox which can be closed by clicking it.
export default SuperLightboxView = (function () {
  SuperLightboxView = class SuperLightboxView extends ControlView {
    static initClass() {
      this.prototype.className = "lightbox"
      this.prototype.tagName = "section"

      this.prototype.leaveWhenEscapePressed = true
      this.prototype.leaveWhenMaskClicked = true
      this.prototype.escapeKeyCode = 27
    }

    initialize(options) {
      this._click = this._click.bind(this)
      this._keydown = this._keydown.bind(this)
      if (options == null) {
        options = {}
      }
      this.bindings = []
      this._bindTo($(document), "keydown", this._keydown)
      this.$el.on("click", this._click)
      this.build()
      return super.initialize(options)
    }

    build() {
      this.$innerEl = $('<div class="lightbox-inner"></div>').appendTo(this.el)
      this.innerEl = this.$innerEl.get(0)
      return $("body").addClass("lightbox-open")
    }

    close() {
      this._unbindFromAll()
      this.off()
      this.remove()
      return $("body").removeClass("lightbox-open")
    }

    // Hide the lightbox if it is clicked.
    _click(event) {
      const $el = $(event.target)
      const href = $el.attr("href")
      if (href && href === "#") {
        event.preventDefault()
      }

      if (this.leaveWhenMaskClicked && $el.is(".lightbox") && this.isVisible()) {
        return this._leave()
      }
    }

    // Hide the lightbox if escape is pressed.
    _keydown(event) {
      if (this.leaveWhenEscapePressed && event.keyCode === this.escapeKeyCode && this.isVisible()) {
        event.preventDefault()
        return this._leave()
      }
    }

    _leave(e) {
      this.trigger("leave", this)
      if (e) {
        e.preventDefault()
      }
      return this.close()
    }

    _bindTo(source, event, callback) {
      source.bind(event, callback)
      return this.bindings.push({ source, event, callback })
    }

    _unbindFromAll() {
      each(this.bindings, (binding) => binding.source.unbind(binding.event, binding.callback))
      return (this.bindings = [])
    }
  }
  SuperLightboxView.initClass()
  return SuperLightboxView
})()
