/* eslint-disable no-new */
/* eslint-disable import/prefer-default-export */

import $ from "jquery"
import React from "react"
import ReactDOM from "react-dom"
import AudioPlayerView from "views/audio_player_view"
import TopbarStayInformedView from "views/topbar_stay_informed_view"
import TopbarToggleMenu from "views/topbar_toggle_menu"
import ErrorTrack from "lib/error_track"
import Flash from "lib/flash"
import initSmallLayout from "layouts/small"
import { isSessionActive } from "lib/currentUser/session"
import TopbarDynamicContentV1 from "containers/TopbarDynamicContent"
import TopbarDynamicContentV2 from "containers/v2/TopbarDynamicContent"
import { eventBus, redirectTo } from "utils"
import TimeFormatter from "lib/time_formatter"

const initAudioPlayers = () => {
  $("audio").each((i, el) => new AudioPlayerView({ audioEl: el }))
}

const initTopbarMenu = () => {
  // Each of the menus we expect to see rendered into the topbar
  const menuSelectors = [".topbar-edition-selector", ".topbar-edition-v2", ".topbar-account-nav"]

  // Now we need to inject some dynamically written CSS. This is absolutely
  // a giant hack which deals with the fact our legacy Javscript has no state
  // control whatsoever.
  //
  // This CSS must live outside the #topbar tree and must not mutate once
  // written so that is able to help persist UI state in the topbar menus
  // even if the #topbar tree gets replaced completely.
  //
  // Ideally, this would be controlled with explicit state in React components
  // and not random css classes being added and removed...
  if (!document.getElementById("topbar-menu-styles")) {
    const topbarMenuStyles = document.createElement("style")
    topbarMenuStyles.id = "topbar-menu-styles"
    topbarMenuStyles.type = "text/css"

    menuSelectors.forEach((selector) => {
      topbarMenuStyles.innerHTML += `
        body${selector}-open ${selector} .menu {
          display: block;
          z-index: 8;
        }

        body${selector}-open ${selector} .menu-button-contents {
          position: relative;
          border-bottom: 1px solid #fff;
          margin-bottom: -1px;
          z-index: 10;
        }
      `
    })

    document.head.appendChild(topbarMenuStyles)
  }

  menuSelectors.forEach((selector) => {
    new TopbarToggleMenu({ el: $(selector), selector })
  })

  new TopbarStayInformedView({ el: $(".topbar-stay-informed") })
}

const initDynamicTopbarContent = async () => {
  const topbarDynamicContentV1Node = document.getElementById("topbar-dynamic-content")

  if (topbarDynamicContentV1Node) {
    const element = React.createElement(TopbarDynamicContentV1, { path: window.location.pathname })
    ReactDOM.render(element, topbarDynamicContentV1Node)
  }

  const TopbarDynamicContentV2Node = document.getElementById("topbar-dynamic-content-v2")

  if (TopbarDynamicContentV2Node) {
    const element = React.createElement(TopbarDynamicContentV2, { path: window.location.pathname })
    ReactDOM.render(element, TopbarDynamicContentV2Node)
  }
}

// We mimic the test from Modernizr here because it was the only feature
// of it we used, and hence, was able to remove it.
export const initSVGHandling = () => {
  if (document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Image", "1.1")) {
    $("html").addClass("svg")
  } else {
    $("html").addClass("no-svg")
  }
}

// Allow CSS to target before and after JS has loaded.
const initJsHandling = () => {
  $("html").addClass("js").removeClass("no-js")
}

// Display times in the local format
const initLocalTimes = () => {
  $("time[data-format]").each((index, el) => {
    const format = $(el).data("format")
    const time = $(el).attr("datetime")
    if (format && time) {
      const dateTime = new Date(time)

      $(el).html(TimeFormatter.formatted(dateTime, format))
      $(el).attr("title", dateTime)
    }
  })
}

// Display certain times as relative (eg. 1 hour ago)
const initRelativeTimes = () => {
  $("time[data-relative]").each((index, el) => {
    const time = $(el).attr("datetime")
    if (time) {
      const dateTime = new Date(time)
      const formattedTime = TimeFormatter.fromNow(dateTime)

      $(el).html(formattedTime)
      $(el).attr("title", dateTime)
    }
  })
}

const initPromoRedirects = () => {
  eventBus.subscribe((e) => {
    switch (e.type) {
      case "redirect":
        if (e.href) {
          redirectTo(e.href)
        } else {
          console.warn("The redirect event requires a href property")
        }
        break

      default:
      // do nothing
    }
  })
}

export const initializeCommonPageElements = () => {
  // Start bugsnag tracking for people with active sessions
  if (isSessionActive()) {
    ErrorTrack.start()
  }

  initAudioPlayers()
  initTopbarMenu()
  initDynamicTopbarContent()
  initSVGHandling()
  initJsHandling()
  initLocalTimes()
  initRelativeTimes()
  initPromoRedirects()

  initSmallLayout()

  Flash.bindServerFlash()
}
