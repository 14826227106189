import i18n from "lib/i18n"
import PropTypes from "prop-types"
import React from "react"
import clsx from "clsx"

import { Dialog } from "../../components/common/Dialog"
import PodcastSubscriptionOption from "../../components/Podcast/SubscriptionOption"
import { Button } from "../../components/common/Button"

class PodcastListenAndSubscribe extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobile: false,
      open: false,
    }

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.updateViewportSize = this.updateViewportSize.bind(this)
  }

  componentDidMount() {
    this.updateViewportSize()
    window.addEventListener("resize", this.updateViewportSize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewportSize)
  }

  updateViewportSize() {
    this.setState({ isMobile: window.innerWidth <= 600 })
  }

  handleOpen() {
    this.setState({ open: true })
  }

  handleClose() {
    this.setState({ open: false })
  }

  renderSubscriptionOptions() {
    const options = Object.entries(this.props.subscriptionOptions).map((subscriptionOption) => {
      const [provider, url] = subscriptionOption
      return <PodcastSubscriptionOption key={provider} provider={provider} url={url} />
    })

    return options
  }

  renderPanel() {
    if (!this.state.isMobile) {
      return (
        <div className="bg-gray-50 p-3 mb-3">
          <h3 className="text-lg font-medium mb-2">
            {i18n.t("podcast.label.listen_and_subscribe")}
          </h3>
          <ul className="space-y-2">{this.renderSubscriptionOptions()}</ul>
        </div>
      )
    }

    return ""
  }

  renderButton() {
    if (this.state.isMobile) {
      return (
        <div className="py-2.5">
          <Button onClick={this.handleOpen} style="secondary">
            {i18n.t("podcast.label.listen_and_subscribe")}
          </Button>
        </div>
      )
    }

    return ""
  }

  render() {
    return (
      <div
        className={clsx("block", {
          "md:hidden": this.state.isMobile,
          "hidden md:block": !this.state.isMobile,
        })}
      >
        {this.renderButton()}
        {this.renderPanel()}

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          title={i18n.t("podcast.label.select_podcast_app")}
        >
          <div className="p-4">
            <ul className="space-y-1">{this.renderSubscriptionOptions()}</ul>
          </div>
          <div className="flex justify-end p-4">
            <Button style="primary" onClick={this.handleClose}>
              {i18n.t("podcast.button.modal.close")}
            </Button>
          </div>
        </Dialog>
      </div>
    )
  }
}

PodcastListenAndSubscribe.propTypes = {
  subscriptionOptions: PropTypes.shape({
    googlepodcasts: PropTypes.string,
    itunes: PropTypes.string,
    pocketcasts: PropTypes.string,
    radiopublic: PropTypes.string,
    rss: PropTypes.string.isRequired,
    spotify: PropTypes.string,
    stitcher: PropTypes.string,
    tunein: PropTypes.string,
  }).isRequired,
}

PodcastListenAndSubscribe.defaultProps = {
  subscriptionOptions: () => {},
}

export default PodcastListenAndSubscribe
