/* eslint-disable jsx-a11y/no-autofocus */

/**
 * We are disabling a11y ESLint rule on autofocus since this form is only used for staff only.
 */

import React, { useState } from "react"
import i18n from "lib/i18n"
import PropTypes from "prop-types"
import clsx from "classnames"
import { Dialog } from "components/common/Dialog"
import { Button } from "components/common/Button"

const ToggleLockUserDialog = ({ locked, onCancel, onSubmit, open, userName }) => {
  const [reason, setReason] = useState("")
  const [valid, setValid] = useState(true)

  const handleSubmit = (event) => {
    event.preventDefault()

    setValid(true)
    if (reason.trim() === "") {
      setValid(false)
      return
    }

    onSubmit(reason)
  }

  const toggleLockText = locked
    ? i18n.t("editorial_toggle_lock_user.action.unlock")
    : i18n.t("editorial_toggle_lock_user.action.lock")

  return (
    <Dialog onClose={onCancel} open={open} title={`${toggleLockText} "${userName}"`}>
      <div className="flex flex-col gap-1">
        <label htmlFor="reason" className="font-bold">
          {i18n.t("editorial_toggle_lock_user.labels.reason")}
        </label>
        <textarea
          autoFocus
          id="reason"
          className={clsx("tailwind h-24 w-96 resize-none", {
            "border border-solid border-red-500": !valid,
          })}
          name="reason"
          onChange={(e) => setReason(e.target.value)}
          required
        />
      </div>

      <div className="mt-4 flex flex-row-reverse gap-4">
        <Button style="primary" onClick={handleSubmit}>
          {i18n.t("editorial_toggle_lock_user.buttons.confirm")}
        </Button>
        <Button style="secondary" onClick={onCancel}>
          {i18n.t("editorial_toggle_lock_user.buttons.cancel")}
        </Button>
      </div>
    </Dialog>
  )
}

ToggleLockUserDialog.propTypes = {
  locked: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userName: PropTypes.string.isRequired,
}

export default ToggleLockUserDialog
