var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from "react";
import clsx from "classnames";
/**
 * The switch component represents a boolean value that can be toggled on or off.
 */
export var Switch = function (_a) {
    var _b = _a.label, label = _b === void 0 ? "" : _b, _c = _a.checked, checked = _c === void 0 ? false : _c, onChange = _a.onChange, rest = __rest(_a, ["label", "checked", "onChange"]);
    var _d = useState(false), focused = _d[0], setFocused = _d[1];
    var switchClasses = clsx("relative w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-800", {
        "border-solid border-4 border-red-300": focused,
    });
    return (<label className="inline-flex items-center cursor-pointer">
      <input type="checkbox" className="sr-only peer" checked={checked} aria-label={label} onChange={function () { return onChange === null || onChange === void 0 ? void 0 : onChange(!checked); }} onFocus={function () { return setFocused(true); }} onBlur={function () { return setFocused(false); }} {...rest}/>

      <div aria-checked={checked} className={switchClasses}></div>
      {label ? <span className="ms-3 text-lg text-black">{label}</span> : null}
    </label>);
};
