import $ from "jquery"
import { bind, each, filter } from "lodash"
/* eslint-disable
    class-methods-use-this,
    consistent-return,
    default-case,
    func-names,
    max-len,
    no-cond-assign,
    no-constant-condition,
    no-multi-assign,
    no-param-reassign,
    no-plusplus,
    no-return-assign,
    no-shadow,
    no-undef,
    no-underscore-dangle,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// This is a custom jQuery plugin you can use to autocomplete a list
// from either a static (array) or dynamic (ajax) source.
//
// The API is similar to jQuery UI's autocomplete plugin, which this is
// a replacement for, but it doesn't support every feature.
//
//
// The basic usage is:
//
// autocomplete $(".something.to-autocomplete"),
//   source: "some/url"
//
//
// You can set a custom select callback to specify what data is
// inserted, and where, when a user makes a selection:
//
// autocomplete $(".something.to-autocomplete"),
//   source: "some/url"
//   select: (event, ui) ->
//     this.val(ui.item.name)
//
//
// You can pass in a static array as the source:
//
// autocomplete $(".something.to-autocomplete"),
//   source: [
//     "cats",
//     "dogs",
//     "chickens",
//     "mice"
//   ]

export default function (jqObject, options) {
  if (options == null) {
    options = {}
  }
  class Suggestions {
    constructor(source, renderCallback) {
      this._delayedUpdate = this._delayedUpdate.bind(this)
      this.renderCallback = renderCallback
      this.setSource(source)
    }

    setSource(source) {
      if (source instanceof Array) {
        this.update = this._arrayUpdate
        return (this.sourceArray = source)
      } else if (source instanceof Function) {
        this.update = this._callbackUpdate
        return (this.sourceCallback = source)
      }
      this.update = this._ajaxUpdate
      return (this.sourceUrl = source)
    }

    cancelRequests() {
      clearTimeout(this.delayedUpdate)
      return this.ajax != null ? this.ajax.abort() : undefined
    }

    _arrayUpdate(query) {
      const results = this._buildResults(query, this.sourceArray)
      return this.renderCallback(results)
    }

    _ajaxUpdate(query) {
      clearTimeout(this.delayedUpdate)
      return (this.delayedUpdate = setTimeout(() => this._delayedUpdate(query), 300))
    }

    _callbackUpdate(query) {
      const results = this._buildResults(query, this.sourceCallback())
      return this.renderCallback(results)
    }

    _delayedUpdate(query) {
      if (this.ajax != null) {
        this.ajax.abort()
      }
      return (this.ajax = $.ajax({
        url: this.sourceUrl,
        data: { term: query },
        dataType: "json",
        success: this.renderCallback,
      }))
    }

    _buildResults(query, collection) {
      return filter(collection, (item) => {
        const itemText = item.label || item
        return itemText.toLowerCase().indexOf(query.toLowerCase()) !== -1
      })
    }
  }

  class Menu {
    constructor(element, renderInline, limit, autoSelect, renderItemToText) {
      let parent
      this._closeOnClickOutside = this._closeOnClickOutside.bind(this)
      this.selectPreviousItem = this.selectPreviousItem.bind(this)
      this.selectNextItem = this.selectNextItem.bind(this)
      this.chooseItem = this.chooseItem.bind(this)
      this.chooseSelectedItem = this.chooseSelectedItem.bind(this)
      this.clear = this.clear.bind(this)
      this.render = this.render.bind(this)
      this._renderItem = this._renderItem.bind(this)
      this.renderItemToText = renderItemToText || this.defaultRenderItemToText
      if (autoSelect == null) {
        autoSelect = false
      }
      this.ul = document.createElement("ul")
      this.ul.className = "autocomplete"

      this.attachElement = element
      this.renderInline = renderInline
      this.limit = limit || 20

      this.autoSelect = autoSelect

      if (this.renderInline) {
        parent = this.attachElement.parentNode
      } else {
        parent = document.body
        this.ul.style.position = "absolute"
      }

      parent.appendChild(this.ul)
      this.clear()

      // Listen for interactions outside of the widget
      $(document).on("mousedown", this._closeOnClickOutside)
    }

    defaultRenderItemToText(item) {
      return item.label || item
    }

    _isEventTargetInWidget(event) {
      return event.target === this.ul || $.contains(this.ul, event.target)
    }

    _closeOnClickOutside(event) {
      if (!this._isEventTargetInWidget(event)) {
        return (this.ul.style.display = "none")
      }
    }

    getSelectedIndex() {
      return this.selectedIndex
    }

    setSelectedIndex(index) {
      if (this.selectedIndex !== index) {
        this._clearSelection()
        this.selectedIndex = index
        return this._updateSelection()
      }
    }

    setSelectionCallback(callback) {
      return (this.callback = callback)
    }

    selectPreviousItem() {
      this._clearSelection()

      if (!this.validSelection()) {
        this.selectedIndex = 0
      } else if (this.selectedIndex > 0) {
        this.selectedIndex--
      }

      return this._updateSelection()
    }

    selectNextItem() {
      this._clearSelection()

      if (!this.validSelection()) {
        this.selectedIndex = 0
      } else if (this.selectedIndex < this.selectionLimit) {
        this.selectedIndex++
      }

      return this._updateSelection()
    }

    chooseItem(event, index) {
      // this maintains compatibility with jqueryui autocomplete for now
      const ui = { item: this.items[index] }

      return typeof this.callback === "function" ? this.callback(event, ui) : undefined
    }

    chooseSelectedItem(event) {
      // Ensure an item is selected and choose it
      if (typeof this.selectedIndex !== "undefined" && this.selectedIndex !== null) {
        this.chooseItem(event, this.selectedIndex)
      }

      return this.clear()
    }

    clear() {
      let child
      this.selectedIndex = undefined
      while ((child = this.ul.firstChild)) {
        this.ul.removeChild(child)
      }
      this.ul.style.display = "none"
      return (this.opened = false)
    }

    render(items) {
      this.items = items.slice(0, this.limit)

      this.clear()
      this.selectionLimit = this.items.length - 1
      each(this.items, this._renderItem)
      this._updateSelection()

      if (!this.renderInline) {
        this.position = this._getAttachmentPoint(this.attachElement)
        this.ul.style.top = `${this.position[0]}px`
        this.ul.style.left = `${this.position[1]}px`
      }

      this.ul.style.display = "block"
      this.opened = true

      if (this.autoSelect) {
        return this.selectNextItem()
      }
    }

    isOpen() {
      return this.opened
    }

    validSelection() {
      return this.selectedIndex !== undefined
    }

    _getAttachmentPoint(element) {
      let top
      let left = (top = 0)

      if (element.offsetParent) {
        top += element.offsetHeight
        while (true) {
          top += element.offsetTop
          left += element.offsetLeft
          if (!(element = element.offsetParent)) {
            break
          }
        }
      }

      return [top, left]
    }

    _renderItem(item, index) {
      const li = document.createElement("li")
      li.className = "autocomplete-item"

      // TODO: go back to innerHTML, but need to work out why
      // it's not working in IE7
      const html = this.renderItemToText(item)
      $(li).html(html)
      this.ul.appendChild(li)

      $(li).on("mouseover", () => this.setSelectedIndex(index))

      return $(li).on("mouseup", (event) => {
        this.chooseItem(event, index)
        return this.clear()
      })
    }

    _clearSelection() {
      return this.ul.childNodes[this.selectedIndex] != null
        ? (this.ul.childNodes[this.selectedIndex].className = "autocomplete-item")
        : undefined
    }

    _updateSelection() {
      let listItem
      if (!(listItem = this.ul.childNodes[this.selectedIndex])) {
        return
      }

      const listHeight = $(this.ul).height()
      const itemHeight = $(listItem).outerHeight()
      const min = $(this.ul).scrollTop()
      const max = listHeight + min
      const itemOffset = itemHeight * this.selectedIndex

      if (itemOffset <= min) {
        $(this.ul).scrollTop(itemOffset)
      }

      if (itemOffset >= max) {
        $(this.ul).scrollTop(itemOffset - (listHeight - itemHeight))
      }

      return (listItem.className = "autocomplete-item selected")
    }
  }

  class Autocomplete {
    constructor(element, options) {
      // jquery ui compatibility callbacks
      this.setSource = this.setSource.bind(this)
      this.handleItemSelection = this.handleItemSelection.bind(this)
      this._handleFocus = this._handleFocus.bind(this)
      this._handleKeyup = this._handleKeyup.bind(this)
      this._handleKeydown = this._handleKeydown.bind(this)
      if (options.select) {
        this.select = bind(options.select, element)
      }

      this.autoFocus = options.autoFocus
      this.element = element
      // disable native autocomplete so we don't get overlapping
      this.element.attr("autocomplete", "off")

      this.menu = new Menu(
        this.element.get(0),
        options.inline,
        options.limit,
        options.autoSelect,
        options.renderItemToText,
      )
      this.menu.setSelectionCallback(this.handleItemSelection)
      this.suggestions = new Suggestions(options.source, this.menu.render)

      this.element.on("keydown", this._handleKeydown)
      this.element.on("keyup", this._handleKeyup)
      this.element.on("focus", this._handleFocus)

      this.element.trigger("autocompletecreate")
    }

    setSource(source) {
      return this.suggestions.setSource(source)
    }

    // this is the callback given to menu to handle item selection
    handleItemSelection(event, ui) {
      // execute the jquery ui compatible callback
      if (typeof this.select === "function") {
        this.select(event, ui)
      }
      this.element.trigger("autocompleteselect", ui)
      return (this.searchText = this.element.val())
    }

    _handleFocus(event) {
      if (this.autoFocus) {
        return this.suggestions.update("")
      }
    }

    _handleKeyup(event) {
      // make sure it's always a string
      const searchText = `${event.target.value}`

      if (this.searchText !== searchText && searchText.length >= 2) {
        this.searchText = searchText
        return this.suggestions.update(searchText)
      }
    }

    _handleKeydown(event) {
      switch (event.which) {
        case 38: // up
          event.preventDefault()
          return this.menu.selectPreviousItem()

        case 40: // down
          event.preventDefault()
          return this.menu.selectNextItem()

        case 27: // esc
          event.preventDefault()
          return this.menu.clear()

        case 13: // return
          // If theres no autocomplete selection, assume the users knows
          // what they want so we can safely close autocomplete
          if (!this.menu.validSelection()) {
            this.suggestions.cancelRequests()
            this.menu.clear()
          }

          // If the menu is open, capture enter, otherwise let it bubble up
          if (this.menu.isOpen() && this.menu.validSelection()) {
            event.preventDefault()
            this.suggestions.cancelRequests()
            return this.menu.chooseSelectedItem(event)
          }
          break
      }
    }
  }

  const autocomplete = new Autocomplete(jqObject, options)
  return jqObject.data("autocomplete", autocomplete)
}
