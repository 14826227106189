/* eslint-disable react/forbid-prop-types, jsx-a11y/no-autofocus */

/**
 * We're disabling the ESLint a11y autofocus rule here because as the removal comments dialog
 * is for internal usage only.
 */

import i18n from "lib/i18n"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Dialog as CommonDialog } from "components/common/Dialog"
import { Button } from "components/common/Button"

import RemovalReasons from "../../RemoveComment/RemovalReasons"

/**
 * React component that'll render the comment removal reason dialog, which can be closed or confirmed.
 * When a user confirms the removal reason, this component is responsible to submit the reason to the server.
 *
 * @param {Function} closeModal function to handle closing the dialog
 * @param {string} dialogTitle optional title for the dialog
 * @param {Function} onSubmitDialog function to be called when dialog is submitted
 * @param {Boolean} open defines if the dialog is opened
 */
function Dialog({ closeModal, dialogTitle, onSubmitDialog, open }) {
  const [removalReason, setRemovalReason] = useState()
  const [notes, setNotes] = useState()

  const handleDialogSubmit = (event) => {
    event.preventDefault()

    onSubmitDialog({ removalReason, notes })
  }

  return (
    <CommonDialog
      onClose={closeModal}
      open={open}
      title={dialogTitle || i18n.t("comment.messages.remove_comment")}
    >
      <RemovalReasons setRemovalReason={setRemovalReason} />

      <div className="my-4 flex flex-col gap-2">
        <label htmlFor="remove-comments-notes" className="font-bold">
          {i18n.t("comment.label.notes")}
        </label>
        <textarea
          autoFocus
          className="h-24 w-96 resize-none text-base"
          id="remove-comments-notes"
          name="notes"
          onChange={(event) => setNotes(event.target.value)}
        />
        <div className="text-xs text-gray-500">{i18n.t("comment.label.notes_hint")}</div>
      </div>

      <div className="flex flex-row-reverse gap-4">
        <Button style="primary" onClick={handleDialogSubmit}>
          {i18n.t("comment.button.confirm")}
        </Button>
        <Button style="secondary" onClick={closeModal}>
          {i18n.t("comment.button.cancel")}
        </Button>
      </div>
    </CommonDialog>
  )
}

Dialog.defaultProps = {
  dialogTitle: null,
}

Dialog.propTypes = {
  closeModal: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string,
  onSubmitDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default Dialog
