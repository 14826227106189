import BasePage from "pages/base_page"
import party from "party-js"

export default class EditorialArticleFinishedPage extends BasePage {
  initialize() {
    super.initialize()

    this.confettiWhenFinished()
    this.celebrateMoreButton()
  }

  confettiWhenFinished() {
    const origin = document.querySelector("article")
    this.confetti({ origin, min: 400, max: 400 })
  }

  celebrateMoreButton() {
    const origin = document.querySelector("article")
    const celebrateMoreButton = document.querySelector(".celebrate-more")

    celebrateMoreButton.addEventListener("click", () => {
      this.confetti({ origin, min: 50, max: 100 })
      this.sparkles({ origin, min: 50, max: 100 })
    })
  }

  confetti({ origin, min = 50, max = 100 }) {
    party.confetti(origin, {
      count: party.variation.range(min, max),
      size: party.variation.range(0.8, 1.2),
    })
  }

  sparkles({ origin, min = 20, max = 40 }) {
    party.sparkles(origin, {
      count: party.variation.range(min, max),
      size: party.variation.range(0.8, 1.2),
    })
  }
}
