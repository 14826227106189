/* eslint-disable class-methods-use-this */

import BasePage from "pages/base_page"
import React from "react"
import ReactDOM from "react-dom"
import ContactAuthorView from "views/contact_author_view"
import ToggleLockUser from "components/Editorial/ToggleLockUser"
import RemoveAllComments from "components/Editorial/CommentModeration/RemoveAllComments"

export default class ProfilePage extends BasePage {
  initialize(options) {
    this.renderContactAuthor = this.renderContactAuthor.bind(this)

    this.user_id = options.user_id
    this.isAuthor = options.is_author
    this.currentUserCanContactAuthors = options.currentUserCanContactAuthors
    this.contactable = options.contactable
    this.availableFor = options.availableFor
    if (this.isAuthor) {
      this.renderContactAuthor()
    }

    super.initialize(options)

    this.renderToggleLockUserButton()
    this.renderRemoveAllCommentsButton()
  }

  renderContactAuthor() {
    const contactAuthor = new ContactAuthorView({
      user_id: this.user_id,
      currentUserCanContactAuthors: this.currentUserCanContactAuthors,
      contactable: this.contactable,
      availableFor: this.availableFor,
      el: this.$el.find(".profile-user-details"),
    })
    return this.$el.find(".profile-user-details").prepend(contactAuthor.el)
  }

  renderToggleLockUserButton() {
    const toggleLockButtons = Array.from(document.querySelectorAll(".toggle-lock-user-button"))
    toggleLockButtons.forEach((button) => {
      const component = React.createElement(ToggleLockUser, {
        buttonClass: "button large",
        locked: button.dataset.locked === "true",
        userId: button.dataset.userId,
        userName: button.dataset.userName,
      })
      ReactDOM.render(component, button)
    })
  }

  renderRemoveAllCommentsButton() {
    const container = document.querySelector(".remove-all-comments-react-component")

    if (container) {
      const { className, removeCommentsPath, userName } = container.dataset

      const component = React.createElement(RemoveAllComments, {
        className,
        removeCommentsPath,
        userName,
      })

      ReactDOM.render(component, container)
    }
  }
}
