import i18n from "lib/i18n"
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { REMOVAL_REASONS, TOP_REMOVAL_REASON } from "../../removalReasons"

/**
 * @param {String} removalReason
 * @return {JSX.Element}
 */
const removalReasonOption = (removalReason) => (
  <option key={removalReason} value={removalReason}>
    {i18n.t(`comment.label.removal_reasons.${removalReason}`)}
  </option>
)

/**
 * A dropdown list of grouped reason options, where the most used reasons are listed at the top of the list.
 */
export default function RemovalReasons({ setRemovalReason }) {
  const firstRemovalReason = TOP_REMOVAL_REASON[0]
  const topRemovalReasonsOptions = TOP_REMOVAL_REASON.map((removalReason) =>
    removalReasonOption(removalReason),
  )
  const removalReasonOptions = REMOVAL_REASONS.map((removalReason) =>
    removalReasonOption(removalReason),
  )

  useEffect(() => setRemovalReason(firstRemovalReason), [setRemovalReason, firstRemovalReason])

  return (
    <div className="flex flex-col gap-2">
      <label htmlFor="remove-comments-removal-reason" className="font-bold">
        {i18n.t("comment.label.reason")}
      </label>
      <select
        id="remove-comments-removal-reason"
        defaultValue={firstRemovalReason}
        onChange={(e) => setRemovalReason(e.target.value)}
        name="removal_reason"
      >
        {topRemovalReasonsOptions}
        <option disabled>──────────</option>
        {removalReasonOptions}
      </select>
    </div>
  )
}

RemovalReasons.propTypes = {
  setRemovalReason: PropTypes.func.isRequired,
}
