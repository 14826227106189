import I18n from "lib/i18n"
import $ from "jquery"
import Minpubsub from "minpubsub"
import { debounce } from "lodash"
/* eslint-disable
    camelcase,
    class-methods-use-this,
    consistent-return,
    func-names,
    guard-for-in,
    max-len,
    no-multi-assign,
    no-restricted-syntax,
    no-return-assign,
    no-shadow,
    no-undef,
    no-unused-vars,
    no-use-before-define,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let SignupProcess

// TODO: Refactor into backbone views, use in NewRegistrationPage.

export default SignupProcess = class SignupProcess {
  constructor() {
    this.steps = []
    this.current_step_index = 0
    this.steps_container = $("#form-steps")

    // construction helpers
    this.build_steps()
    this.attach_subscribers()
  }

  serialise_to_json() {
    const payload = {}
    for (const step of Array.from(this.steps)) {
      const object = step.serialise_to_json()
      for (const key in object) {
        const val = object[key]
        payload[key] = val
      }
    }
    return { author: payload }
  }

  // Private Methods
  build_steps() {
    // Order here is signficant
    this.steps.push(new VerifyStep(this.steps.length))
    this.steps.push(new EducationStep(this.steps.length))
    return this.steps.push(new AccountStep(this.steps.length))
  }

  has_previous_step(index) {
    return index > 0 && index < this.steps.length
  }

  previous_step(index) {
    if (this.has_previous_step(index)) {
      return this.steps[index - 1]
    }
    return false
  }

  is_last_step(index) {
    return index + 1 === this.steps.length
  }

  attach_subscribers() {
    Minpubsub.subscribe("/data-submission/from", (step_index) =>
      $.ajax({
        type: "POST",
        url: this.is_last_step(step_index) ? "/become-an-author" : "/become-an-author?dry_run=true",
        data: this.serialise_to_json(),
        dataType: "json",
        success: (data) => {
          this.steps[step_index].restore_submit_button()

          if (data.errors && !this.steps[step_index].is_valid(data.errors)) {
            return this.steps[step_index].show_errors(data.errors)
          } else if (this.is_last_step(step_index)) {
            return (document.location = data.redirect)
          }
          this.steps[step_index].hide_errors()
          return Minpubsub.publish("/step-change/to", [step_index + 1])
        },

        error: () => this.steps[step_index].restore_submit_button(),
      }),
    )

    return Minpubsub.subscribe("/step-change/to", (index) => {
      if (index >= 0 && index < this.steps.length) {
        this.current_step_index = index
        $("#form-steps").removeClass("stepped-1 stepped-2")
        return $("#form-steps").addClass(`stepped-${this.current_step_index}`)
      }
    })
  }
}

class Step {
  constructor(index) {
    this.index = index
    this.form = $($("#form-steps form")[this.index])
    this.submit_button = this.form.find("input[type=submit]")
    this.nav = $($("#nav-steps li")[this.index])

    this.form.submit(() => {
      this.disable_submit_button()
      Minpubsub.publish("/data-submission/from", [this.index])
      return false
    })

    this.nav.click(() => {
      if (this.nav.hasClass("navigable")) {
        return Minpubsub.publish("/step-change/to", [this.index])
      }
    })

    // Construction helpers
    this.attach_subscribers()
  }

  current_step() {
    return this.nav.hasClass("on")
  }

  nav_on() {
    return this.nav.addClass("on")
  }

  nav_off() {
    return this.nav.removeClass("on")
  }

  nav_clickable() {
    return this.nav.addClass("navigable")
  }

  nav_not_clickable() {
    return this.nav.removeClass("navigable")
  }

  disable_submit_button() {
    return this.submit_button.val("Processing ...").attr("disabled", "disabled")
  }

  restore_submit_button() {
    return this.submit_button.val(this.submit_button.data("original-text")).removeAttr("disabled")
  }

  // Private
  text_field(selector) {
    return this.form.find(selector).first().val()
  }

  attach_subscribers() {
    return Minpubsub.subscribe("/step-change/to", (step_index) => {
      if (step_index === this.index) {
        this.nav_on()
      } else {
        this.nav_off()
      }
      if (this.index < step_index) {
        return this.nav_clickable()
      }
      return this.nav_not_clickable()
    })
  }

  is_valid(errors) {
    let valid = true
    for (const field of Array.from(this.fields)) {
      if (errors[field] != null) {
        valid = false
      }
    }
    return valid
  }

  show_errors(errors) {
    this.hide_errors()
    // Populate the corresponding error blocks for each field that is erroneous
    return (() => {
      const result = []
      for (const field_name of Array.from(this.fields)) {
        if (errors[field_name]) {
          const field = $(
            `input[name='registration_form[${field_name}]'], select[name='registration_form[${field_name}]'], textarea[name='registration_form[${field_name}]']`,
          )
          field.parent().addClass("error")
          field.parent().append("<p class='inline-errors'></p>")
          const error_box = field.siblings(".inline-errors")
          error_box.html(errors[field_name])
          result.push(error_box.show())
        } else {
          result.push(undefined)
        }
      }
      return result
    })()
  }

  hide_errors() {
    // Remove any error styles for the form input
    this.form.find("li.error").removeClass("error")

    // Identify all the error message blocks
    const errors = this.form.find("p.inline-errors")
    return errors.each((index, error) => $(error).remove())
  }
}

class VerifyStep extends Step {
  static initClass() {
    this.prototype.fields = ["email", "institution_name", "byline_title", "full_name"]
  }
  constructor(index) {
    super(index)
    this.autodetectRequest = null

    $("#registration_form_email").on(
      "change keyup input",
      debounce(this.autodetectInstitution.bind(this), 250),
    )
  }

  serialise_to_json() {
    return {
      email: this.text_field("#registration_form_email"),
      institution_name: this.text_field("#registration_form_institution_name"),
      full_name: this.text_field("#registration_form_full_name"),
      byline_title: this.text_field("#registration_form_byline_title"),
    }
  }

  getEmailFieldDomain() {
    const email = $("#registration_form_email").val().toLowerCase()
    const parts = email.split("@")
    const domain = parts[parts.length - 1]

    return domain
  }

  // Private
  autodetectInstitution() {
    if (this.autodetectRequest !== null) {
      this.autodetectRequest.abort()
    }

    let name
    const domain = this.getEmailFieldDomain()
    const institutionField = $("#registration_form_institution_name_input")

    const institutionDetails = $(".institution-details")

    const autosetInstitution = function (name, logoUrl) {
      institutionField.find(".inline-errors").hide()
      institutionField.find("input").val(name)
      institutionDetails.html(`<img src='${logoUrl}'/><p>${name}</p>`)
    }

    const cleanInstitution = function () {
      institutionField.find(".inline-errors").show()
      institutionField.find("input").val("")
      institutionDetails.html(
        I18n.t("author_registration.error.couldnt_match_domain_to_institution", {
          email: I18n.t("common.region_support_email_address"),
        }),
      )
    }

    const updateInstitution = function (data) {
      if (data.query !== this.getEmailFieldDomain()) {
        return
      }

      if (data.results && data.results.length > 0) {
        autosetInstitution(data.results[0].label, data.results[0].institution.logo_url)
      } else {
        cleanInstitution()
      }
    }

    this.autodetectRequest = $.ajax({
      url: "/institutions/matching",
      data: { domain },
      method: "GET",
      success: updateInstitution.bind(this),
    })
  }
}
VerifyStep.initClass()

class EducationStep extends Step {
  static initClass() {
    this.prototype.fields = [
      "education_year",
      "education_award",
      "education_institution",
      "summary",
    ]
  }

  serialise_to_json() {
    return {
      education_year: this.text_field("#registration_form_education_year"),
      education_award: this.text_field("#registration_form_education_award"),
      education_institution: this.text_field("#registration_form_education_institution"),
      summary: this.text_field("#registration_form_summary"),
    }
  }
}
EducationStep.initClass()

class AccountStep extends Step {
  static initClass() {
    this.prototype.fields = ["password", "password_confirmation", "secondary_email"]
  }

  serialise_to_json() {
    return {
      password: this.text_field("#registration_form_password"),
      password_confirmation: this.text_field("#registration_form_password_confirmation"),
      secondary_email: this.text_field("#registration_form_secondary_email"),
    }
  }
}
AccountStep.initClass()
