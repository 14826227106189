import React from "react"
import PropTypes from "prop-types"
import clsx from "classnames"
import {
  ArrowDownOnSquareIcon,
  ChartBarIcon,
  Cog8ToothIcon,
  NewspaperIcon,
  PencilSquareIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline"

const ActionIcon = ({ name, small }) => {
  const commonProps = {
    className: clsx("-ml-1/2 pr-1", {
      "w-4": small,
      "w-5": !small,
    }),
  }

  switch (name) {
    case "edit":
      return <PencilSquareIcon {...commonProps} />
    case "chart":
      return <ChartBarIcon {...commonProps} />
    case "readers": // topic sponsorships
      return <SquaresPlusIcon {...commonProps} />
    case "article":
      return <NewspaperIcon {...commonProps} />
    case "republish": // merge topic
      return <ArrowDownOnSquareIcon {...commonProps} />
    default:
      return <Cog8ToothIcon {...commonProps} />
  }
}

ActionIcon.propTypes = {
  name: PropTypes.string,
  small: PropTypes.bool,
}

export default ActionIcon
