var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import clsx from "classnames";
/**
 * Button implementation, following the UI v3 design system.
 */
export var Button = function (_a) {
    var children = _a.children, onClick = _a.onClick, _b = _a.style, style = _b === void 0 ? "primary" : _b, rest = __rest(_a, ["children", "onClick", "style"]);
    return (<button className={clsx("cursor-pointer rounded-full border border-solid border-black px-6 py-2 font-semibold hover:border-gray-700 hover:bg-gray-700 hover:text-white", {
            "bg-black text-white": style === "primary",
            "bg-white text-black": style === "secondary",
        })} onClick={onClick} {...rest}>
      {children}
    </button>);
};
