import $ from "jquery"

const toggleSidebar = () => {
  const openButton = $("#small-sidebar-open-button")
  const closeButton = $("#small-sidebar-close-button")
  const sidebar = $("#small-sidebar")
  const wrapper = $("#page-wrapper")

  if (sidebar.hasClass("visible")) {
    openButton.removeClass("visible")
    closeButton.removeClass("visible")
    sidebar.removeClass("visible")
    wrapper.removeClass("overlay-visible")
  } else {
    openButton.addClass("visible")
    closeButton.addClass("visible")
    sidebar.addClass("visible")
    wrapper.addClass("overlay-visible")
  }
}

const toggleSidebarV2 = () => {
  const openButton = document.getElementById("small-sidebar-open-button")
  const closeButton = document.getElementById("small-sidebar-close-button")
  const sidebar = document.getElementById("small-sidebar")
  const wrapper = document.getElementById("page-wrapper")

  if (openButton?.classList.contains("visible")) {
    openButton?.classList.remove("visible", "opacity-0", "z-10")
    closeButton?.classList.remove("!opacity-100", "z-20")
    sidebar?.classList.remove(
      "max-lg:!h-full",
      "[&_.small-sidebar-group]:top-0",
      "[&_.small-sidebar-group]:opacity-100",
    )
    wrapper?.classList.remove("max-lg:!h-full", "max-lg:overflow-hidden")
  } else {
    openButton?.classList.add("visible", "opacity-0", "z-10")
    closeButton?.classList.add("!opacity-100", "z-20")
    sidebar?.classList.add(
      "max-lg:!h-full",
      "[&_.small-sidebar-group]:top-0",
      "[&_.small-sidebar-group]:opacity-100",
    )
    wrapper?.classList.add("max-lg:!h-full", "max-lg:overflow-hidden")
  }
}

export default async () => {
  $("#small-region-select-dropdown").on("change", (event) => {
    window.location = event.target.value
  })

  if (document.getElementById("topbar-dynamic-content-v2")) {
    $("#small-sidebar-open-button").on("click", toggleSidebarV2)
    $("#small-sidebar-close-button").on("click", toggleSidebarV2)
  } else {
    $("#small-sidebar-open-button").on("click", toggleSidebar)
    $("#small-sidebar-close-button").on("click", toggleSidebar)
  }
}
