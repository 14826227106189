import React from "react"
import PropTypes from "prop-types"
import I18n from "lib/i18n"
import { menuLinkStyle } from "../AccountMenu/shared"
import ActionIcon from "components/v2/ActionIcon"
import clsx from "classnames"

const returnToPath = () => {
  if (window && window.location) {
    return `?return_to=${window.location.pathname}`
  }

  return ""
}

const DashboardNav = ({ user, role }) => {
  if (!["Editor", "Author"].includes(role)) {
    return null
  }

  const dashboardLink =
    role === "Editor" ? `/editorial/dashboards/${user.id}` : `/profiles/${user.id}/dashboard`

  return (
    <div className="flex h-full">
      <a
        className={clsx(
          menuLinkStyle,
          "border-solid border-0 border-r border-gray-200 hidden md:inline-flex",
        )}
        href={dashboardLink}
      >
        {I18n.t("top_bar.label.dashboard")}
      </a>
    </div>
  )
}

const UnauthenticatedActions = () => (
  <div className="flex h-full">
    <a
      className={clsx(
        menuLinkStyle,
        "border-solid border-0 border-r border-gray-200 hidden md:inline-flex",
      )}
      href="/become-an-author"
    >
      {I18n.t("top_bar.label.author_sign_up")}
    </a>
    <a
      className={clsx(
        menuLinkStyle,
        "border-solid border-0 border-r border-gray-200 hidden md:inline-flex",
      )}
      href={`/sign_up${returnToPath()}`}
    >
      {I18n.t("top_bar.label.reader_sign_up")}
    </a>
    <a
      className={clsx(
        menuLinkStyle,
        "border-solid border-0 border-r border-gray-200 max-sm:!py-0 max-sm:!px-2",
      )}
      href={`/sign_in${returnToPath()}`}
    >
      <svg
        aria-label={I18n.t("top_bar.label.sign_in")}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 md:hidden"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        />
      </svg>
      <span className="hidden md:inline-flex">{I18n.t("top_bar.label.sign_in")}</span>
    </a>
  </div>
)

const TopbarAdminShortcuts = ({ adminActions }) => {
  const firstAction = adminActions[0]

  if (!firstAction) {
    return null
  }

  return (
    <div data-testid="admin-shortcuts" className="flex h-full">
      <a
        href={firstAction.path}
        className={clsx(
          menuLinkStyle,
          "border-solid border-0 border-r border-gray-200 hidden md:inline-flex",
        )}
      >
        {firstAction.icon ? <ActionIcon small name={firstAction.icon} /> : null}
        {firstAction.label}
      </a>
    </div>
  )
}

const TopbarViewerActions = ({ adminActions, user, role }) => {
  if (user) {
    return (
      <React.Fragment>
        <DashboardNav user={user} role={role} />
        <TopbarAdminShortcuts adminActions={adminActions} />
      </React.Fragment>
    )
  }

  return <UnauthenticatedActions />
}

DashboardNav.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  role: PropTypes.string.isRequired,
}

DashboardNav.defaultProps = {
  user: undefined,
  adminActions: [],
  role: "Reader",
}

TopbarViewerActions.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  role: PropTypes.string,
  adminActions: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
    }),
  ),
}

TopbarAdminShortcuts.propTypes = {
  adminActions: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
    }),
  ).isRequired,
}

TopbarViewerActions.defaultProps = {
  user: undefined,
}

export default TopbarViewerActions
