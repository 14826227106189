import i18n from "lib/i18n"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { ajax as $ajax } from "jquery"
import Flash from "lib/flash"

import Dialog from "../RemoveComment/Dialog"

/* eslint-disable max-len */
/**
 * The remove all comments of a user component.
 * This component renders a link that'll trigger a dialog, where editors must choose the removal reason
 * for all user comments.
 *
 * You can opt out from showing the button and trigger the dialog manually by:
 * => passing `false` to the `showButton` prop
 * => controlling the dialog opened state through the `dialogOpen` prop
 *
 * @param {String} [buttonText] this will only appear when `showButton` is true
 * @param {String} [className] class attribute of the link to trigger the dialog
 * @param {Function} [closeDialogCallback] callback function when the dialog closes. Useful when you want to control state
 * @param {Boolean} [dialogOpen=false] determines if the dialog is opened
 * @param {String} removeCommentsPath path to submit the remove all comments POST action
 * @param {Boolean} [showButton=true] shows or hide action button
 * @param {String} userName the name to appear inside the dialog
 */
/* eslint-enable max-len */
export default function RemoveAllComments({
  buttonText,
  className,
  closeDialogCallback,
  dialogOpen,
  removeCommentsPath,
  showButton,
  userName,
}) {
  const [modalOpen, setModalOpen] = useState(dialogOpen)
  const buttonCopy =
    buttonText ||
    i18n.t("editorial_comments.actions.remove_all_comments_by_user", { name: userName })

  useEffect(() => setModalOpen(dialogOpen), [dialogOpen])

  const onSubmitDialog = ({ removalReason, notes }) => {
    $ajax({
      url: removeCommentsPath,
      method: "POST",
      dataType: "json",
      data: {
        notes,
        reason: removalReason,
      },
      success: () => {
        location.reload()
      },
      error: () => {
        Flash.now("alert", i18n.t("editorial_comments.error.message"))
      },
    })
  }

  return (
    <>
      {showButton ? (
        <a
          href="#"
          className={className}
          onClick={(event) => {
            event.preventDefault()
            setModalOpen(true)
          }}
        >
          {buttonCopy}
        </a>
      ) : null}

      <Dialog
        closeModal={() => {
          closeDialogCallback()
          setModalOpen(false)
        }}
        dialogTitle={i18n.t("editorial_comments.headers.remove_all_comments")}
        onSubmitDialog={onSubmitDialog}
        open={modalOpen}
        userName={userName}
      />
    </>
  )
}

RemoveAllComments.defaultProps = {
  buttonText: "",
  className: "destroy danger button",
  closeDialogCallback: () => {},
  dialogOpen: false,
  showButton: true,
}

RemoveAllComments.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  closeDialogCallback: PropTypes.func,
  dialogOpen: PropTypes.bool,
  removeCommentsPath: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
  userName: PropTypes.string.isRequired,
}
