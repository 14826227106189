import i18n from "lib/i18n"
import PropTypes from "prop-types"
import React from "react"
import styles from "./styles.scss"

import applePodcastLogo from "./apple_podcasts.svg"
import googlePodcastLogo from "./google_podcasts.svg"
import spotifyLogo from "./spotify.svg"
import stitcherLogo from "./stitcher.svg"
import radioPublicLogo from "./radio_public.svg"
import tuneInLogo from "./tune_in.svg"
import pocketCastsLogo from "./pocket_casts.svg"
import rssLogo from "./rss.svg"

const LOGOS = {
  googlepodcasts: googlePodcastLogo,
  itunes: applePodcastLogo,
  pocketcasts: pocketCastsLogo,
  radiopublic: radioPublicLogo,
  rss: rssLogo,
  spotify: spotifyLogo,
  stitcher: stitcherLogo,
  tunein: tuneInLogo,
}

const PodcastSubscriptionOption = ({ provider, url }) => {
  const label = i18n.t(`podcast.label.subscribe_to_${provider}`)

  return (
    <li className={styles.subscriptionOption}>
      <img className={styles.icon} alt={label} src={LOGOS[provider]} />
      <a className={styles.link} href={url} target="_blank" rel="noreferrer">
        {label}
      </a>
    </li>
  )
}

PodcastSubscriptionOption.defaultProps = {
  provider: "",
  url: "",
}

PodcastSubscriptionOption.propTypes = {
  provider: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default PodcastSubscriptionOption
