import i18n from "lib/i18n"
import $ from "jquery"
import { template } from "lodash"
import { View } from "backbone"
import SuperSliderView from "views/super_slider_view"
/* eslint-disable
    class-methods-use-this,
    consistent-return,
    constructor-super,
    func-names,
    max-len,
    no-constant-condition,
    no-eval,
    no-multi-assign,
    no-param-reassign,
    no-restricted-properties,
    no-return-assign,
    no-shadow,
    no-this-before-super,
    no-undef,
    no-underscore-dangle,
    no-unused-expressions,
    no-unused-vars,
    radix,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let AudioPlayerView

export default AudioPlayerView = (function () {
  AudioPlayerView = class AudioPlayerView extends View {
    static initClass() {
      this.prototype.events = { "click .audio-player-play": "_onClickPlay" }

      this.prototype.className = "audio-player"

      this.prototype.template = template(`\
<div class="audio-player-inner">
  <div class="audio-player-middle"></div>
</div>\
`)
    }

    initialize(options) {
      this._play = this._play.bind(this)
      this._onClickPlay = this._onClickPlay.bind(this)
      this._onTimeUpdate = this._onTimeUpdate.bind(this)
      this._onEnded = this._onEnded.bind(this)
      this.audioEl = $(options.audioEl)

      // if an audio player has already initialised, exit early
      if (this.audioEl.hasClass("initialized")) {
        return
      }

      super.initialize(options)

      return this.render()
    }

    render() {
      super.render()
      this.$el.append(this.template())

      this.innerEl = this.$(".audio-player-inner")
      this.middleEl = this.$(".audio-player-middle")

      if (this._canPlayAudio()) {
        this._setupSupported()
      } else {
        this._setupUnsupported()
      }

      this.audioEl.addClass("initialized")
      return this.audioEl.before(this.$el)
    }

    _play() {
      this.audio.play()

      return (this.timeUpdateInterval = setInterval(() => $(this.audio).trigger("timeupdate"), 50))
    }

    _pause() {
      this.audio.pause()

      if (this.timeUpdateInterval) {
        return clearInterval(this.timeUpdateInterval)
      }
    }

    _canPlayAudio() {
      const audio = document.createElement("audio")

      return !!audio.canPlayType
    }

    _setupSupported() {
      this.audio = this.audioEl.get(0)

      $(this.audio).on("timeupdate", this._onTimeUpdate)
      $(this.audio).on("ended", this._onEnded)

      this._readData()
      this._removeCaption()
      this._addImage()
      this._addControls()
      this._addDownloadLink()
      return this._addTitle()
    }

    _setupUnsupported() {
      this.audio = this.audioEl.get(0)

      $(this.audio).on("timeupdate", this._onTimeUpdate)
      $(this.audio).on("ended", this._onEnded)

      this._readData()
      this._removeCaption()
      this._addImage()
      this._addLicenseWithoutControls()
      this._addDownloadLink()
      this._addTitle()

      this.$el.addClass("no-audio")
      const unsupportedMessage = `<span class="unsupported">${i18n.t(
        "audio_player.message.unsupported",
      )}</span>`
      this.middleEl.find(".audio-player-listen").append(unsupportedMessage)
      return this.audioEl.next(".audio-player-caption").show()
    }

    _readData() {
      this.duration = this.audioEl.data("duration")
      this.image = this.audioEl.data("image")
      this.title = this.audioEl.data("title")
      this.size = this.audioEl.data("size")
      this.source = this.audioEl.data("source")
      this.sourceUrl = this.audioEl.data("source-url")
      this.license = this.audioEl.data("license")
      this.licenseUrl = this.audioEl.data("license-url")

      this.link = this.audioEl.find("source").attr("src")

      this.durationFormatted = this._formatSeconds(this.duration)
      return (this.sizeFormatted = this._formatSize(this.size))
    }

    // We've established we can use Javascript and HTML5 audio, so let's remove the fallback caption
    _removeCaption() {
      // For lead audio
      this.audioEl.next(".audio-player-caption").remove()

      // For body audio
      return this.audioEl.parent().next(".audio-player-caption").remove()
    }

    _addImage() {
      if (this.image !== undefined && this.image !== "") {
        return this.innerEl.append(`\
<div class="audio-player-image">
  <img src="${this.image}" alt="">
</div>\
`)
      }
      return this.$el.addClass("no-image")
    }

    _addLicenseWithoutControls() {
      const lic =
        this.licenseUrl !== undefined && this.licenseUrl !== ""
          ? `<a href="${this.licenseUrl}">${this.license}</a>`
          : `<span>${this.license}</span>`

      return this.middleEl.append(`\
<div class="audio-player-license audio-player-unsupported-license">${lic}</div>\
`)
    }

    _addControls() {
      const lic =
        this.licenseUrl !== undefined && this.licenseUrl !== ""
          ? `<a href="${this.licenseUrl}">${this.license}</a>`
          : `<span>${this.license}</span>`

      this.middleEl.append(`\
<div class="audio-player-controls">
  <div class="audio-player-play"></div>

  <div class="audio-player-bar-wrapper">
    <div class="audio-player-current-time">00:00</div>
    <div class="audio-player-total-time">${this.durationFormatted}</div>
    <div class="audio-player-license">${lic}</div>
  </div>
</div>\
`)

      this.wrapperEl = this.$(".audio-player-bar-wrapper")
      this.playEl = this.$(".audio-player-controls .audio-player-play")
      this.currentTimeEl = this.$(".audio-player-controls .audio-player-current-time")
      this.totalTimeEl = this.$(".audio-player-controls .audio-player-total-time")

      this.superSliderView = new SuperSliderView()
      this.superSliderView.$el.appendTo(this.wrapperEl)

      this.superSliderView.on("changestart", () => {
        if (this.playEl.is(".playing")) {
          this._pause()
          return this.superSliderView.once("changefinish", this._play)
        }
      })

      return this.superSliderView.on("change", (percentage) => {
        this._updateCurrentTime(percentage)
        return this._updateCurrentTimestamp(percentage)
      })
    }

    _addDownloadLink() {
      const format = this.link.split(".").pop().toUpperCase()

      return this.innerEl.append(`\
<div class="audio-player-download">
  <a href="${this.link}" title="Download">
    <span class="audio-player-heading">${i18n.t("audio_player.label.download")}</span>
    <span class="audio-player-download-icon"></span>
    <span class="audio-player-download-info">${format} / ${this.sizeFormatted}</span>
  </a>
</div>\
`)
    }

    _addTitle() {
      return this.middleEl.append(`\
<div class="audio-player-listen">
  <span class="audio-player-heading">${i18n.t("audio_player.label.listen")}</span>
  <span class="audio-player-title">${this.title}</span>
</div>\
`)
    }

    _moveSlider(toPercent) {
      return this.superSliderView.setValue(toPercent)
    }

    // Fast forward or rewind audio to specific position
    _updateCurrentTime(toPercent) {
      const secs = (this.duration * (toPercent > 100 ? 100 : toPercent)) / 100

      if (Number.isFinite(secs)) {
        this.audio.currentTime = secs
      }
    }

    // Update the time display for current time
    _updateCurrentTimestamp(percentage) {
      const secs = this.duration * (percentage / 100)

      return (this.currentTimeEl.get(0).textContent = this._formatSeconds(secs)) // calling this is really slow on mobile
    }

    //
    // Callbacks
    //

    _onClickPlay(event) {
      const btn = $(event.target)
      btn.toggleClass("playing")

      if (btn.is(".playing")) {
        return this._play()
      }
      return this._pause()
    }

    _onTimeUpdate(event) {
      const toPercent = this._timeToPercent(this.audio.currentTime)

      this._moveSlider(toPercent)
      return this._updateCurrentTimestamp(toPercent)
    }

    _onEnded(event) {
      this.playEl.removeClass("playing")

      if (this.timeUpdateInterval) {
        clearInterval(this.timeUpdateInterval)
      }

      this._moveSlider(0)
      return this._updateCurrentTimestamp(0)
    }

    //
    // Helper methods
    //

    // Formats seconds as HH:MM:SS
    _formatSeconds(secs) {
      secs = Math.round(secs)
      const hours = Math.floor(secs / (60 * 60))

      const divisorForMinutes = secs % (60 * 60)
      const minutes = Math.floor(divisorForMinutes / 60)

      const divisorForSeconds = divisorForMinutes % 60
      const seconds = Math.ceil(divisorForSeconds)

      if (hours === 0) {
        return `${this._padded(minutes)}:${this._padded(seconds)}`
      }
      return `${this._padded(hours)}:${this._padded(minutes)}:${this._padded(seconds)}`
    }

    _padded(number) {
      let str = `${number}`

      while (str.length < 2) {
        str = `0${str}`
      }

      return str
    }

    // Formats bytes as '637 KB'
    _formatSize(bytes) {
      const sizes = ["bytes", "KB", "MB", "GB"]

      if (bytes === 0) {
        return "0"
      }

      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

      return `${i === 0 ? bytes / Math.pow(1024, i) : (bytes / Math.pow(1024, i)).toFixed(0)} ${
        sizes[i]
      }`
    }

    // Returns the percentage of time based on total duration
    _timeToPercent(time) {
      const percent = (parseFloat(time) / parseInt(this.duration)) * 100

      if (percent > 100) {
        return 100
      }
      return percent
    }
  }
  AudioPlayerView.initClass()
  return AudioPlayerView
})()
