// HIGH SPEED LOW DRAG
const LowDrag = function(options) {
  this.initialize(options);
  this.dragProperties = new DragProperties();
}

class DragProperties {
  constructor() {
    this.TIME_CHUNK = 50.0; // milliseconds
    this.dragging = false;
    this.mouseStartPosition = { x: 0, y: 0 };
    this.mousePosition = { x: 0, y: 0 };
    this.startPosition = { x: 0, y: 0 };
    this.targetPosition = { x: 0, y: 0 };
    this.interpolatedPosition = { x: 0, y: 0 };
    this.sliderPosition = { x: 0, y: 0 };

    this.containment = { active: false, xMin: 0, xMax: 0, yMin: 0, yMax: 0 };
    this.timeAccumulator = 0;
    this.previousFrameTime = 0;
  }

  setNewStartPosition(x, y) {
    this.startPosition.x = x;
    this.startPosition.y = y;
    this.targetPosition.x = x;
    this.targetPosition.y = y;
  }

  setNewTargetPosition() {
    this.copyTargetToStart();
    this.targetPosition.x = this.mousePosition.x - this.mouseStartPosition.x;
    this.targetPosition.y = this.mousePosition.y - this.mouseStartPosition.y;
  }

  copyTargetToStart() {
    this.startPosition.x = this.targetPosition.x;
    this.startPosition.y = this.targetPosition.y;
  }

  setContainment(bounds) {
    this.containment.active = true;
    this.containment.xMin = bounds.xMin;
    this.containment.xMax = bounds.xMax;
    this.containment.yMin = bounds.yMin;
    this.containment.yMax = bounds.yMax;
  }

  interpolate(alpha) {
    this.interpolatedPosition.x = this.targetPosition.x * alpha + this.startPosition.x * (1 - alpha);
    this.interpolatedPosition.y = this.targetPosition.y * alpha + this.startPosition.y * (1 - alpha);
  }
}

LowDrag.prototype = {
  initialize: function(options) {
    this.element = options.element;
    this.axis = options.axis;
    this.containment = options.containment;

    const handleMousedown = this._handleMousedown.bind(this);
    const handleTouchstart = this._handleTouchstart.bind(this);
    const handleTrackMousedown = this._handleTrackMousedown.bind(this);
    const handleTrackTouchstart = this._handleTrackTouchstart.bind(this);
    const handleResize = this._handleResize.bind(this);
    this.element.addEventListener("mousedown", handleMousedown, false);
    this.element.addEventListener("touchstart", handleTouchstart, false);
    window.addEventListener('resize', handleResize, false);

    // If there is a containment track, allow mouse events on it as well
    if (this.containment && this.containment == "parent") {
      this.element.parentNode.addEventListener("mousedown", handleTrackMousedown, false);
      this.element.parentNode.addEventListener("touchstart", handleTrackTouchstart, false);
    }
  },

  // this only really works for x at the moment, bit of a hack
  // this code is mostly workarounds for iOS...
  setValue: function(value) {
    this.dragProperties.sliderPosition.x = value;

    if(!this.dragProperties.dragging) {
      const translation = "translate3d("+this.dragProperties.sliderPosition.x+"px, 0 , 0)";

      this.element.style.transform = translation;
      this.element.style.webkitTransform = translation; // webkit worst
    }
  },

  setPercentValue: function(percent) {
    if (this.element.parentNode.offsetWidth || this.element.parentNode.offsetHeight) {
      const offsetLeft = percent * this.element.parentNode.offsetWidth / 100.0;
      this.setValue(offsetLeft);
    }
  },

  _beginDrag: function(element) {
    this.handleMousemove = this._handleMousemove.bind(this);
    this.handleTouchmove = this._handleTouchmove.bind(this);
    this.handleMouseup = this._handleMouseup.bind(this);
    document.addEventListener('mousemove', this.handleMousemove, false);
    document.addEventListener('touchmove', this.handleTouchmove, false);
    document.addEventListener('mouseup', this.handleMouseup, false);
    document.addEventListener('touchend', this.handleMouseup, false);

    this.dragProperties.dragging = true;
    this.dragProperties.setNewStartPosition(this.dragProperties.sliderPosition.x, this.dragProperties.sliderPosition.y);

    if (this.containment && this.containment == "parent") {
      this.dragProperties.setContainment({
        xMin: 0,
        xMax: element.parentNode.offsetWidth,
        yMin: 0,
        yMax: element.parentNode.offsetHeight
      })
    }

    var dragStartEvent = new CustomEvent("dragstart", {});
    this.element.dispatchEvent(dragStartEvent);

    requestAnimationFrame(this._renderCallback.bind(this));
  },

  _renderCallback: function(timeNow) {
    var frameTimeDelta = timeNow - this.dragProperties.previousFrameTime;
    this.dragProperties.timeAccumulator += frameTimeDelta;

    while(this.dragProperties.timeAccumulator >= this.dragProperties.TIME_CHUNK) {
      this.dragProperties.setNewTargetPosition();
      this.dragProperties.timeAccumulator -= this.dragProperties.TIME_CHUNK;
    }

    var alpha = this.dragProperties.timeAccumulator / this.dragProperties.TIME_CHUNK;

    this.dragProperties.interpolate(alpha);
    this.dragProperties.sliderPosition.x = this.dragProperties.interpolatedPosition.x;

    // TODO: can this be combined into the setNewTargetPosition method above?
    if (this.dragProperties.containment.active) {
      this.dragProperties.sliderPosition.x = Math.max(
        this.dragProperties.containment.xMin, Math.min(
          this.dragProperties.containment.xMax, this.dragProperties.sliderPosition.x));
    }

    // TODO: and this - would help limit the events being emitted too
    var dragEvent = new CustomEvent("drag", { detail: { position: this.dragProperties.sliderPosition }});
    this.element.dispatchEvent(dragEvent);

    const translation = "translate3d("+this.dragProperties.sliderPosition.x+"px, 0 , 0)";

    this.element.style.transform = translation;
    this.element.style.webkitTransform = translation; // webkit worst

    // TODO: backwards compatibility/transform feature detection
    // below is the fallback code
    // this.element.style.left = this.dragProperties.interpolatedPosition.x + "px";
    // this.element.style.top = this.dragProperties.interpolatedPosition.y + "px";



    if (this.dragProperties.dragging) {
      requestAnimationFrame(this._renderCallback.bind(this));
    } else {
      var dragFinishEvent = new CustomEvent("dragfinish", {});
      this.element.dispatchEvent(dragFinishEvent);
    }

    this.dragProperties.previousFrameTime = timeNow;
  },

  _finishDrag: function(element) {
    this.dragProperties.dragging = false;
  },

  _handleMousemove: function(event) {
    event.preventDefault();
    this.dragProperties.mousePosition.x = event.pageX;
    this.dragProperties.mousePosition.y = event.pageY;
  },

  _handleTouchmove: function(event) {
    event.preventDefault();
    this.dragProperties.mousePosition.x = event.touches[0].pageX;
    this.dragProperties.mousePosition.y = event.touches[0].pageY;
  },

  _handleMousedown: function(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dragProperties.mouseStartPosition.x = event.pageX - this.dragProperties.sliderPosition.x;
    this.dragProperties.mouseStartPosition.y = event.pageY - this.dragProperties.sliderPosition.y;
    this.dragProperties.mousePosition.x = event.pageX;
    this.dragProperties.mousePosition.y = event.pageY;

    this._beginDrag(this.element);
  },

  _handleTouchstart: function(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dragProperties.mouseStartPosition.x = event.touches[0].pageX - this.dragProperties.sliderPosition.x;
    this.dragProperties.mouseStartPosition.y = event.touches[0].pageY - this.dragProperties.sliderPosition.y;
    this.dragProperties.mousePosition.x = event.touches[0].pageX;
    this.dragProperties.mousePosition.y = event.touches[0].pageY;

    this._beginDrag(this.element);
  },

  _handleTrackMousedown: function(event) {
    // Move the slider to mousedown position on the track
    this._snapToX(event.pageX);

    // Then treat as a regular touch from there
    this._handleMousedown(event);
  },

  _handleTrackTouchstart: function(event) {
    // Move the slider to touch position on the track
    this._snapToX(event.touches[0].pageX);

    // Then treat as a regular touch from there
    this._handleTouchstart(event);
  },

  _handleMouseup: function(event) {
    event.preventDefault();
    document.removeEventListener('mousemove', this.handleMousemove);
    document.removeEventListener('touchmove', this.handleTouchmove);
    document.removeEventListener('mousemove', this.handleMouseup);
    document.removeEventListener('touchend', this.handleMouseup);

    this._finishDrag(this.element);
  },

  _handleResize: function(event) {
    // redetermine position within resized containment
    var previousWidth = this.dragProperties.containment.xMax;
    var previousHeight = this.dragProperties.containment.yMax;
    var x = (this.dragProperties.sliderPosition.x / previousWidth) * this.element.parentNode.offsetWidth;
    var y = (this.dragProperties.sliderPosition.y / previousHeight) * this.element.parentNode.offsetHeight;

    // set new containment properties
    this.dragProperties.containment.xMax = this.element.parentNode.offsetWidth;
    this.dragProperties.containment.yMax = this.element.parentNode.offsetHeight;

    // update position of the slider
    this.dragProperties.targetPosition.x = x;
    this.dragProperties.targetPosition.y = y;
    this.dragProperties.sliderPosition.x = x;
    this.dragProperties.sliderPosition.y = y;

    // set the styles
    var translation = "translate3d("+this.dragProperties.sliderPosition.x+"px, 0 , 0)";

    this.element.style.transform = translation;
    this.element.style.webkitTransform = translation; // webkit worst
  },

  _snapToX: function(x) {
    var offset = this.element.parentNode.getBoundingClientRect();

    var min = offset.left
    var max = this.element.parentNode.offsetWidth + offset.left;

    // Make sure we clamp values!
    var x = Math.max(min, Math.min(max, x)) - offset.left;

    this.dragProperties.sliderPosition.x = x;
  },
}

export default LowDrag
